export default function buildAPIUrl(resourcePath){
    return process.env.REACT_APP_BACKEND_URL+"/"+resourcePath;
    // let current = window.origin;
    // if(current.includes("localhost:") || current.includes("127.0.0.1:") || current.includes("https://bing-search-api-test-staging.herokuapp.com")){
    //     return "https://research-bookmark-staging-api.herokuapp.com/"+resourcePath;
    // }else if(current.includes("rbbeta") || current.includes("researchbookmark.io")){
    //     return "https://research-bookmark-prod-api.herokuapp.com/"+resourcePath;
    // }else{
    //     return "http://localhost:3030/"+resourcePath;
    // }
}

