import logo from './logo.svg';
import {useEffect, useState} from 'react';
import './App.css';
import './User/Components/Landing/landing.css'
import './User/Components/ResultsCategories/search.css'
import './User/Components/RBResourceTables/resources.css'
import './User/Components/Personalization/personalization.css'
import './User/Components/RBOriginals/originals.css'
import './User/Components/Modals/modals.css'
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Main from "./User/Components/Landing/Main";
import Results2 from "./User/Components/ResultsCategories/Results2";
import SocialMedia from "./User/Components/ResultsCategories/SocialMedia";
import Papers from "./User/Components/ResultsCategories/Papers";
import Careers from "./User/Components/ResultsCategories/Careers";
import Courses from "./User/Components/ResultsCategories/Courses";
import Images from "./User/Components/ResultsCategories/Images";
import Vocabulary from "./User/Components/RBResourceTables/Vocabulary";
import Conferences from "./User/Components/RBResourceTables/Conferences";
import Communities from "./User/Components/RBResourceTables/Communities";
import Tools from "./User/Components/RBResourceTables/Tools";
import MyLibrary from "./User/Components/Personalization/MyLibrary";
import SavedSources from "./User/Components/Personalization/SavedSources";
import Favorite from "./User/Components/Personalization/Favorite";
import Trends from "./User/Components/Personalization/Trends";
import OriginalsList from "./User/Components/RBOriginals/OriginalsList";
import OriginalsDetails from "./User/Components/RBOriginals/OriginalsDetails";
import RedirectPage from "./User/Components/RedirectPage";

import Webinar from "./User/Components/Webinar/Webinar";
import ChromeExtensionSearchResults from "./User/Components/ResultsCategories/ChromeExtensionSearchResults";
import MainNotion from "./User/Components/Notion/MainNotion";
import "./User/Components/Notion/notion.css"
import NotionArticleDetails from "./User/Components/Notion/NotionArticleDetails";
import ResourcesMain from "./User/Components/RBResourceTables/ResourcesMain";
import NotionResults from "./User/Components/Notion/NotionResults";
import PaymentTable from "./User/Components/Payment/PaymentTable";
import Checkout from "./User/Components/Payment/Checkout";
import PaymentSuccess from "./User/Components/Payment/PaymentSuccess";
import Videos from "./User/Components/ResultsCategories/Videos";
import Volunteering from "./User/Components/RBResourceTables/Volunteering";
import Podcasts from "./User/Components/RBResourceTables/Podcasts";
import Books from "./User/Components/RBResourceTables/Books";
import UxCourses from "./User/Components/RBResourceTables/UxCourses";
import Methods from "./User/Components/RBResourceTables/Methods";
import Participate from "./User/Components/Landing/MainFile/Participate";
import AdminMain from "./Admin/AdminMain";
import SignedUpUsers from "./Admin/SignedUpUsers";
import AllSearches from "./Admin/AllSearches";
import AllSavedSources from "./Admin/AllSavedSources";
import CreateResearchCommunity from "./Admin/Community/CreateCommunity";
import ListOfResearchCommunity from "./Admin/Community/ListAllCommunities";
import EditResearchCommunity from "./Admin/Community/EditCommunity";
import ListOfConference from "./Admin/Conference/ListOfConference";
import CreateConference from './Admin/Conference/CreateConference';
import EditConference from './Admin/Conference/EditConference';
import ListOfVocabularies from './Admin/Vocabularies/ListOfVocabularies';
import CreateVocabulary from './Admin/Vocabularies/CreateVocabulary';
import EditVocabulary from './Admin/Vocabularies/EditVocabulary';
import ListAllOpportunities from './Admin/VolunteerOpportunities/ListAllOpportunities';
import CreateOpportunity from './Admin/VolunteerOpportunities/CreateOpportunities';
import EditOpportunity from './Admin/VolunteerOpportunities/EditOpportunities';
import ListAllResearchMethods from './Admin/ResearchMethods/ListAllMethods';
import CreateMethod from './Admin/ResearchMethods/CreateMethod';
import EditMethod from './Admin/ResearchMethods/EditMethod';
import ListAllBooks from './Admin/Books/ListAllBooks';
import CreateBook from './Admin/Books/CreateBook';
import EditBook from './Admin/Books/EditBook';
import Terms from "./User/Components/Landing/Terms";
import PrivacyPolicy from "./User/Components/Landing/PrivacyPolicy";
import Features from "./User/Components/Landing/Features/Features";
import UserSettings from "./User/Components/Personalization/UserSettings";
import Login from "./User/Components/Authentication/Login";
import Analyzing from "./User/Components/Notion/Analyzing";
import ListAllPodcasts from "./Admin/PodCasts/ListAllPodcasts";
import CreatePodcast from "./Admin/PodCasts/CreatePodcast";
import EditPodcast from "./Admin/PodCasts/EditPodcast";
import ListAllCourses from "./Admin/Courses/ListAllCourses";
import CreateCourse from "./Admin/Courses/CreateCourse";
import EditCourse from "./Admin/Courses/EditCourse";
import ListAllTools from "./Admin/ResearchTools/ListAllTools";
import CreateTool from "./Admin/ResearchTools/CreateTool";
import EditTool from "./Admin/ResearchTools/EditTool";
import Signup from "./User/Components/Authentication/Signup";
import HttpsRedirect from 'react-https-redirect';
import {EventsHandler} from "./User/Components/Landing/MainFile/MainFunctions";
import mixpanel from "mixpanel-browser";
import CertificatesList from "./User/Components/Webinar/Certificates/CertificatesList";
import News from "./User/Components/ResultsCategories/News";
import Workshop2022 from "./User/Components/Webinar/Certificates/Workshop2022";
import Workshop2023 from "./User/Components/Webinar/Certificates/Workshop2023";
import EventsList from "./User/Components/Events/EventsList";
import SupportRB from "./User/Components/SupportRBPage/SupportRB";
import VerifyCertificate from "./User/Components/Webinar/Certificates/VerifyCertificate";
import Collections from "./Admin/Collections/Collections";
import CollectionsLanding from "./Admin/Collections/CollectionsLanding";
import CollectionsList from "./Admin/Collections/CollectionsList";
import SubCollections from "./Admin/Collections/SubCollections";
import SubCollectionDetails from "./Admin/Collections/SubCollectionDetails";
import AddCollections from "./Admin/Collections/AddCollections";
import ReferralSignup from "./User/Components/Authentication/ReferralSignup";
import EditCollections from "./Admin/Collections/EditCollections";
import CommunitySubCollections from "./User/Components/RBResourceTables/SpecialCollections/CommunitySubCollections";
import CommunitySubCollectionDetails from "./User/Components/RBResourceTables/SpecialCollections/CommunitySubCollectionDetails";
import SpecialCollections from "./User/Components/RBResourceTables/SpecialCollections/SpecialCollections";
import SpecialCollectionsDashboard from "./User/Components/RBResourceTables/SpecialCollections/SpecialCollectionsDashboard";
import Organizations from "./Admin/Collections/Organizations";
import EverydayCollections from "./User/Components/RBResourceTables/SpecialCollections/EverydayCollections";
import SearchResullts from "./User/Components/RBResourceTables/SearchResullts";
import AddCollectionsManually from "./Admin/Collections/AddCollectionsManually";
import Google from "./Admin/Collections/Google";
import JanuaryBadge from "./User/Components/Webinar/JanuaryBadge/JanuaryBadge";
import NewUser from "./User/Components/Landing/MainFile/NewUser";
import NewUserCheckout from "./User/Components/Landing/MainFile/NewUserCheckout";
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import Recordings from "./User/Components/Webinar/Recordings";
import TrialSuccess from "./User/Components/Payment/TrialSuccess";
import ReactGA from "react-ga";
import Pricing from "../src/User/Components/Pricing/Pricing";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  const [userToken, setUserToken]=useState(localStorage.getItem('token'))
  const [loaded, setLoaded]=useState(false)
  window.addEventListener("onunload", (event) => {
    EventsHandler("RB Window Closed",  document.URL)
    mixpanel.time_event("page_exit");
  });
  return (

        
        <HttpsRedirect>
      
          <div className="App">
            <Router>
              <Switch>
                <Route exact path="/" component={Main} />
                <Route exact path="/all-results" component={Results2} />
                <Route exact path="/social-media" component={SocialMedia} />
                <Route exact path="/papers" component={Papers} />
                <Route exact path="/careers" component={Careers} />
                <Route exact path="/courses" component={Courses} />
                <Route exact path="/images" component={Images} />
                <Route exact path="/research-vocabulary" component={Vocabulary} />
                <Route exact path="/uxr-conferences" component={Conferences} />
                <Route exact path="/uxr-communities" component={Communities} />
                <Route exact path="/research-tools" component={Tools} />
                <Route exact path="/my-library" component={MyLibrary} />
                <Route exact path="/saved-sources" component={SavedSources} />
                <Route exact path="/favorites" component={Favorite} />
                <Route exact path="/search-iq" component={Trends} />
                <Route exact path="/rb-originals" component={OriginalsList} />
                <Route exact path="/templates/:id" component={OriginalsDetails} />
                <Route exact path="/webinar" component={Webinar} />
                <Route exact path="/webinar/videos" component={Webinar} />
                <Route exact path="/search-results/:id" component={ChromeExtensionSearchResults} />
                <Route exact path={"/articles/:id/:title"} component={MainNotion} />
                <Route exact path={"/article/:id"} component={NotionArticleDetails} />
                <Route exact={true} path={"/collections"} component={ResourcesMain} />
                <Route exact={true} path={"/articles-search-results"} component={NotionResults} />
                <Route exact={true} path={"/rb-plans"} component={PaymentTable} />
                <Route exact={true} path={"/checkout"} component={Checkout} />
                <Route exact path="/videos" component={Videos} />
                <Route exact={true} path={"/payment-success-page"} component={PaymentSuccess} />
                <Route exact={true} path={"/trial-success-page"} component={TrialSuccess} />
                <Route exact={true} path={"/volunteer-opportunities"} component={Volunteering} />
                <Route exact={true} path={"/ux-podcasts"} component={Podcasts} />
                <Route exact={true} path={"/research-books"} component={Books} />
                <Route exact={true} path={"/uxr-courses"} component={UxCourses} />
                <Route exact={true} path={"/uxr-methods"} component={Methods} />
                <Route exact={true} path={"/participate-in-research"} component={Participate} />
                <Route exact={true} path={"/analyzing-research"} component={Analyzing} />

                {/* <Route exact={true} path={"/support_rb"} component={SupportRB} /> */}

                <Route exact path="/signed-up-users" component={SignedUpUsers} />
                <Route exact path="/all-searches" component={AllSearches} />
                <Route exact path="/all-saved-sources" component={AllSavedSources} />
                <Route exact path="/linkedin" component={LinkedInCallback} />
                <Route exact path="/sign-up" component={Signup} />
                <Route exact path="/uxr-communities/create">
                  {userToken && <CreateResearchCommunity />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/uxr-communities/all">
                  {userToken && <ListOfResearchCommunity />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/uxr-communities/edit/:community_id">
                  {userToken && <EditResearchCommunity />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/conference/all">
                  {userToken && <ListOfConference />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/conference/create">
                  {userToken && <CreateConference />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/conference/edit/:conference_id">
                  {userToken && <EditConference />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-dashboard">
                  {userToken && <AdminMain />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/vocabulary/all">
                  {userToken && <ListOfVocabularies />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/vocabulary/create">
                  {userToken && <CreateVocabulary />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/vocabulary/edit/:vocabulary_id">
                  {userToken && <EditVocabulary />}
                  {!userToken && <Redirect to='/' />}
                </Route>

                <Route exact path="/volunteer_opportunity/all">
                  {userToken && <ListAllOpportunities />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/volunteer_opportunity/create">
                  {userToken && <CreateOpportunity />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/volunteer_opportunity/edit/:opportunity_id">
                  {userToken && <EditOpportunity />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/research_methods/all">
                  {userToken && <ListAllResearchMethods />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/research_methods/create">
                  {userToken && <CreateMethod />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/research_methods/edit/:method_id">
                  {userToken && <EditMethod />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/books/all">
                  {userToken && <ListAllBooks />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/books/create">
                  {userToken && <CreateBook />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/books/create/edit/:book_id">
                  {userToken && <EditBook />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/podcasts/all">
                  {userToken && <ListAllPodcasts />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/podcasts/create">
                  {userToken && <CreatePodcast />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/podcasts/edit/:podcast_id">
                  {userToken && <EditPodcast />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/courses/all">
                  {userToken && <ListAllCourses />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/courses/create">
                  {userToken && <CreateCourse />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/courses/edit/:course_id">
                  {userToken && <EditCourse />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/research_tools/all">
                  {userToken && <ListAllTools />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/research_tools/create">
                  {userToken && <CreateTool />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                {/* <Route exact path="/certificates-list">
                  {userToken && <CertificatesList />}
                  {!userToken && <Redirect to='/' />}
                </Route> */}
                <Route exact path="/skillshop-2022">
                  {userToken && <Workshop2022 />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/skillshop-2023">
                  {userToken && <Workshop2023 />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/research_tools/edit/:tool_id">
                  {userToken && <EditTool />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact={true} path={"/user-settings"}>
                  {userToken && <UserSettings />}
                  {!userToken && <Redirect to='/join-rb' />}
                </Route>
                <Route exact path="/admin-collections">
                  {userToken && <Collections />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-collections-search">
                  {userToken && <CollectionsLanding />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-collections-list">
                  {userToken && <CollectionsList />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-subcollections">
                  {userToken && <SubCollections />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-organizations">
                  {userToken && <Organizations />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-subcollection-details">
                  {userToken && <SubCollectionDetails />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-add-collections">
                  {userToken && <AddCollections />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-add-collections-manually">
                  {userToken && <AddCollectionsManually />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact path="/admin-edit-collection/:id">
                  {userToken && <EditCollections />}
                  {!userToken && <Redirect to='/' />}
                </Route>
                <Route exact={true} path={"/terms-of-use"} component={Terms} />
                <Route exact={true} path={"/privacy-policy"} component={PrivacyPolicy} />
                <Route exact path="/features" component={Features} />
                <Route exact={true} path={"/special-collections/:id/:name"} component={CommunitySubCollections} />
                <Route exact={true} path={"/special-collections-details/:name"} component={CommunitySubCollectionDetails} />
                <Route exact={true} path={"/sub-collections-members"} component={SpecialCollectionsDashboard} />

                <Route exact={true} path={"/community-registration/:id"} component={Signup} />
                <Route exact={true} path={"/referral-registration/:id"} component={ReferralSignup} />
                <Route exact={true} path={"/join-rb"} component={Login} />
                <Route exact path="/news" component={News} />
                  <Route exact path={"/special-collections"} component={SpecialCollections} />
                  <Route exact path={"/everyday-collections"} component={EverydayCollections} />
                  <Route exact path={"/search-results"} component={SearchResullts} />
                <Route exact={true} path={"/events"} component={EventsList} />
                <Route exact={true} path={"/google-search"} component={Google} />
                <Route exact={true} path={"/january-badge"} component={JanuaryBadge} />
                <Route exact={true} path={"/certificate-verification/:id"} component={VerifyCertificate} />


                <Route exact={true} path={"/payment-plans"}>
                  {userToken && <NewUser />}
                  {!userToken && <Redirect to='/' />}
                </Route>

                <Route exact={true} path={"/webinar-recordings"} component={Recordings} />


                <Route exact={true} path={"/pricing"} component={Pricing} />

                <Route exact={true} path={"/new-user-payment"} component={NewUserCheckout} />
                <Route exact path="*" component={RedirectPage} />



                {/*    <Route exact path="/linkedin" component={LinkedInCallback} />*/}
                {/*









            */}
              </Switch>
            </Router>
          </div>
        </HttpsRedirect>

  );
}

export default App;
